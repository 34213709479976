export default {
  "about": {
    "AE_PSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE PSP<br>杜拜經濟與旅遊部"])},
    "AE_PSP_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：1312503"])},
    "AE_RERA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE RERA<br>迪拜房地产监管局"])},
    "AE_RERA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：1172628"])},
    "ASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIC <br>澳洲證券與投資委員會 (支付服務)"])},
    "ASIC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：444062"])},
    "AU_ASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIC <br>澳洲證券與投資委員會（金融交易）"])},
    "AU_ASIC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：222650"])},
    "AU_AUSTRAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUSTRAC <br>澳洲交易報告和分析中心"])},
    "AU_AUSTRAC-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUSTRAC​<br>澳大利亞交易報告和分析中心​"])},
    "AU_AUSTRAC-1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獨立匯款: IND100844845-001"])},
    "AU_AUSTRAC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獨立匯款：IND100812107-001 <br>數字貨幣交易所：DCE100812107-001 "])},
    "BVI_SFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BVI SFC <br/>英屬維京群島金融服務委員會"])},
    "BVI_SFC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資經理的批準證書: IBR/AIM/24/2085"])},
    "CA_FINTRAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA FINTRAC <br>加拿大金融交易與報告分析中心"])},
    "CA_FINTRAC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSB 註冊編號: M19501973"])},
    "CBUAE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CBUAE<br>阿聯酋中央銀行保險經紀牌照"])},
    "CBUAE_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：226"])},
    "CY_SEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CySEC<br>塞浦路斯證券交易委員會"])},
    "CY_SEC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塞浦路斯投資公司授權核准編號：CIF 448/24"])},
    "HK_DPMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK DPMS<br>香港海关（贵金属及宝石交易商的监理科）"])},
    "HK_DPMS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：A-B-24-04-06541"])},
    "HK_EAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK EAA <br>香港地產管理監管局 "])},
    "HK_EAA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：C-094694  "])},
    "HK_Insurance_Broker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK Insurance Broker<br> 香港保險業監管局（香港保險經紀牌照）"])},
    "HK_Insurance_Broker_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：FB1823 "])},
    "HK_MSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK MSO <br>香港海關 （香港金錢服務經營者牌照）"])},
    "HK_MSO_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：18-06-02444 "])},
    "HK_SFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK SFC<br>香港证券及期货事务监察委员会（第 1 、4、9 类牌照）"])},
    "HK_SFC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：BSM562 "])},
    "HK_TCSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK TCSP<br>香港公司注册处（香港信托或公司服务提供者牌照）"])},
    "HK_TCSP_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司編號：TC006709"])},
    "HK_Trust_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK Trust Company<br> 香港公司註冊處（香港註冊信託公司）"])},
    "HK_Trust_Company_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司編號：2601567  "])},
    "ID_BAPPEBTI_FBL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID BAPPEBTI<br>印尼商品期貨交易監管機構"])},
    "ID_BAPPEBTI_FBL_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期貨經紀業務許可證: 376/BAPPEBTI/SI/VI/2004"])},
    "ID_BAPPEBTI_SPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID BAPPEBTI<br>印尼商品期貨交易監管機構"])},
    "ID_BAPPEBTI_SPA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為另類交易系統參與者批准: 1159/BAPPEBTI/SI/3/2007"])},
    "KY_CIMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KY CIMA <br/>開曼群島金融管理局"])},
    "KY_CIMA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資基金註冊編號: 2147585"])},
    "MU_FSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MU FSC <br>毛里求斯金融服務委員會 "])},
    "MU_FSC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：C119023907"])},
    "MY_Labuan_FSA-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY Labuan FSA <br>馬來西亞納閩金融服務管理局"])},
    "MY_Labuan_FSA-1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["證券牌照編號：SL/23/0022 "])},
    "MY_Labuan_FSA-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY Labuan FSA <br>馬來西亞納閩金融服務管理局"])},
    "MY_Labuan_FSA-2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融貨幣經紀牌照編號：MB/23/0108"])},
    "PCI_DSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCI DSS <br>支付卡產業安全標準委員會"])},
    "PCI_DSS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付卡產業資料安全標準合規報告編號：C1551-01-PIT1-B"])},
    "SC_FSA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SC FSA <br>塞舌爾金融服務管理局"])},
    "SC_FSA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：SD090 "])},
    "SG_MAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG MAS<br>新加坡金融管理局"])},
    "SG_MAS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：CMS101428"])},
    "UK_FCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK FCA <br>英國金融行為監管局"])},
    "UK_FCA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：833414 "])},
    "US_FINCEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US FinCEN <br>美國金融犯罪執法局"])},
    "US_FINCEN_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSB 註冊編號: 31000255627030"])},
    "US_FINRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US FINRA <br>美國金融業監管局 "])},
    "US_FINRA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：CRD 24409"])},
    "US_SEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US SEC <br>美國證券交易委員會"])},
    "US_SEC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：SEC 8-41551 "])},
    "VU_FSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VU FSC <br>瓦努阿圖金融服務委員會 "])},
    "VU_FSC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照編號：700238 "])},
    "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "betterFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索美好未來"])},
    "betterFutureDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 都會控股集團成立於2014 年，總部位於新加坡，是一家以金融科技為核心的國際金融服務集團，透過深度佈局全球券商業務、財富管理、支付兌換以及不動產四大核心業務線。 透過各領域業務的協同發展，Doo Group 持續完善商業生態圈，致力為全球客戶提供全方位的金融服務與創新解決方案，共同探索美好未來。 "])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌發展"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商業務"])},
    "businessAndBrands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元化業務和品牌"])},
    "businessAndBrandsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的團隊遍佈世界各地，並持續吸納優秀人才。我們擁有多個子品牌，並在不同的業務範疇上提供服務和方案。立即查看我們的品牌和服務！"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
    "developmentJourney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的發展歷程"])},
    "developmentJourneyDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 自 2014 年創立至今，發展十大業務線，佈局全球市場，構建多元金融生態體系，持續為全球 200,000+ 客戶提供完善的金融服務。"])},
    "ems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杜拜（Emaar Square）"])},
    "financialTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球佈局"])},
    "global-office-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 視服務全球投資者為使命，不斷擴大全球業務版圖，滿足全球客戶需求"])},
    "global-office-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球運營中心"])},
    "global-operations-centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帶您遊覽我們的全球營運中心"])},
    "group-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元金融服務，一站達至美好未來"])},
    "group-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集團介紹"])},
    "hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "jlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杜拜（JLT）"])},
    "kl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉隆坡"])},
    "license-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 始終秉承「客戶為先」理念，不斷完善金融生態，受國際頂尖金融監管機构嚴格監管，保障客戶資產安全"])},
    "license-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["監管牌照"])},
    "m-2014-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 成立，以金融科技集團模式運營"])},
    "m-2014-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立核心研發及技術支持團隊"])},
    "m-2014-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立中國香港辦公室"])},
    "m-2015-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引入國際認可的專業交易軟件 MT4，提供熱門貨幣對產品"])},
    "m-2016-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立韓國首爾辦公室"])},
    "m-2016-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拓展貴金屬、能源及全球主要股票交易指數"])},
    "m-2016-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發布 BrokerForce v3.0，全面提升系統穩定性及用戶體驗"])},
    "m-2017-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Clearing 清算品牌，為經紀商提供優質的流動性接入與清算服務 "])},
    "m-2017-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立英國倫敦辦公室"])},
    "m-2017-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索引入新一代交易軟件 MT5，支持更多強大功能"])},
    "m-2017-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次推出 Bridge Cloud，為經紀商提供頂級流動性清算橋接服務"])},
    "m-2017-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得瓦努阿圖金融服務委員會 FSC 牌照"])},
    "m-2018-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合零售客戶業務，推出全新品牌 Doo Prime"])},
    "m-2018-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立中國台北辦公室"])},
    "m-2018-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立馬來西亞吉隆坡辦公室"])},
    "m-2018-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo MT5 交易平台正式啟用，更強大更輕鬆，革新用戶交易體驗"])},
    "m-2018-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BrokerForce v 4.0 升級亮相"])},
    "m-2018-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港海關香港金錢服務經營者牌照"])},
    "m-2019-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機構客戶達到 400+"])},
    "m-2019-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立新加坡辦公室"])},
    "m-2019-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立毛裏求斯辦公室"])},
    "m-2019-g3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立塞舌爾辦公室"])},
    "m-2019-g4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立瓦努阿圖辦公室"])},
    "m-2019-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不斷拓展交易品種，接入更豐富美股、期貨交易產品"])},
    "m-2019-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與多家金融科技公司合作推出 InTrade 交易終端 及 Outrade 社區化交易平台"])},
    "m-2019-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯通 TradingView 雲端交易平台，協助客戶交流海量的圖表指標和交易想法"])},
    "m-2019-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與 Myfxbook 建立戰略合作夥伴關系，強勢助推 AutoTrade 鏡像交易系統"])},
    "m-2019-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得英國金融行為監管局 FCA 牌照"])},
    "m-2019-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港公司註冊處 TCSP 牌照"])},
    "m-2019-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得加拿大金融交易與報告分析中心 FINTRAC 牌照"])},
    "m-2020-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收購擁有 30 年歷史的美國券商 Peter Elish"])},
    "m-2020-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 FinPoints 一站式金融技术提供品牌"])},
    "m-2020-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與Trading Central 建立合作夥伴關系，打造更多樣更全面的專業技術分析工具"])},
    "m-2020-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立美國達拉斯辦公室"])},
    "m-2020-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立越南胡志明市辦公室"])},
    "m-2020-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 300+ 個明星美港股及期貨產品"])},
    "m-2020-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進駐全球領先的外匯交易社區 FOLLOWME，攜手提升客戶的社交交易體驗"])},
    "m-2020-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與一站式金融教育平台 MTE Media 聯手提供海量的投資者教育資源"])},
    "m-2020-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作打造 Price Cloud 報價分發系統，提供極速穩定的多個市場報價接入及整合服務"])},
    "m-2020-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供 VPS 交易專用服務器，為客戶建構更安全穩定的交易環境"])},
    "m-2020-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得美國證監會 SEC 及金融業監管局 FINRA 牌照"])},
    "m-2020-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得毛裏求斯金融服務委員會 FSC 牌照"])},
    "m-2020-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購買了專業責任賠償保險 (PII)，全方位保障客戶資產安全和自身權益"])},
    "m-2021-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Financial 互聯網券商品牌  "])},
    "m-2021-b10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 推出「慈善之星」 計劃 "])},
    "m-2021-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客戶達 40,000+  "])},
    "m-2021-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機構夥伴達到 250+ "])},
    "m-2021-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 與 盈透建立全披露券商關系 "])},
    "m-2021-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與 oneZero 建立合作夥伴關系 "])},
    "m-2021-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與 Abans 建立戰略合作夥伴關系 "])},
    "m-2021-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與 Bison Bank 簽訂合作備忘錄 MOU "])},
    "m-2021-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 收購澳洲經紀商 Kinsmen Securities Limited "])},
    "m-2021-b9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["於英國倫敦國王學院成立 「Doo」 助學金 "])},
    "m-2021-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立泰國曼谷辦公室"])},
    "m-2021-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立阿聯酋迪拜辦公室"])},
    "m-2021-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作推進 Clearing Cloud 大型多邊清算系統，協助業內不同規模的經紀商連接流動性提供商"])},
    "m-2021-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為交易者提供超過 10,000 款交易產品"])},
    "m-2021-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Prime TradingView 交易界面"])},
    "m-2021-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Prime InTrade 2.1.0 版本"])},
    "m-2021-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 推出 3.0 版本官網、媒體中心、幫助中心"])},
    "m-2021-p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 推出泰國版積分商城"])},
    "m-2021-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得澳洲證券和投資委員會 ASIC 金融交易牌照"])},
    "m-2021-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得塞舌爾金融服務局 FSA 牌照"])},
    "m-2022-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Wealth 財富管理品牌"])},
    "m-2022-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客戶達 60,000+"])},
    "m-2022-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 收購財富管理公司 Doo Wealth Management HK Limited"])},
    "m-2022-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成為阿德萊德聯足球俱樂部官方合作夥伴"])},
    "m-2022-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攜手 UNICEF HK 支援「促進兒童福祉」"])},
    "m-2022-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開放日本、印度尼西亞市場"])},
    "m-2022-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Prime InTrade 2.6.4 版本"])},
    "m-2022-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 上線 MT5 交易平台"])},
    "m-2022-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime CopyTrading 正式上線"])},
    "m-2022-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港保險業監管局 IA 牌照"])},
    "m-2022-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲批香港證券及期貨事務監察委員會  SFC 第 4 類和第 9 類牌照"])},
    "m-2022-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港地產管理監管局 EAA 牌照"])},
    "m-2022-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得澳洲交易報告和分析中心 AUSTRAC 牌照"])},
    "m-2023-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户达 200,000+"])},
    "m-2023-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Payment 支付兌換品牌"])},
    "m-2023-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Academy 理財教育品牌"])},
    "m-2023-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Health 大健康品牌"])},
    "m-2023-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成為曼聯足球俱樂部官方全球合作夥伴"])},
    "m-2023-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成為西澳洲州澳中友好協會（ACFSWA）榮譽贊助商"])},
    "m-2023-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成為雪隆痙孿兒童協會黃金贊助商"])},
    "m-2023-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Payment 收購匯款和數字貨幣兌換提供商 Doo Exchange AU Pty Ltd"])},
    "m-2023-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立澳洲悉尼辦公室和客服中心"])},
    "m-2023-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港公司註冊處香港註冊信託公司牌照"])},
    "m-2023-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得馬來西亞納閩金融服務管理局  FSA 牌照"])},
    "m-2023-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得杜拜房地產監管局 RERA 牌照"])},
    "m-2023-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得美國金融犯罪執法局 FinCEN 執照"])},
    "m-2024-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發布 Doo Property 不動產品牌"])},
    "m-2024-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Consulting 顧問業務品牌"])},
    "m-2024-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發布 Doo Cloud 雲端服務品牌"])},
    "m-2024-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Digital Plus 數字營銷品牌"])},
    "m-2024-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶覆蓋國家/地區達150+"])},
    "m-2024-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客戶達 400,000+"])},
    "m-2024-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 的 ETF 產品在香港交易所成功上市"])},
    "m-2024-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入 Star Nest，投入 Web3 音樂新生態"])},
    "m-2024-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港證券及期貨事務監察委員會 SFC 第 1 類牌照"])},
    "m-2024-s10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得開曼群島金融管理局 CIMA 投資基金牌照"])},
    "m-2024-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得新加坡金融管理局 MAS 牌照"])},
    "m-2024-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得杜拜經濟與旅遊部 PSP 牌照"])},
    "m-2024-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得阿聯酋中央銀行 CBUAE 保險經紀牌照"])},
    "m-2024-s5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得澳洲證券與投資委員會 ASIC 支付服務牌照"])},
    "m-2024-s6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得香港海關貴金屬及寶石交易商的監理科 DPMS 牌照"])},
    "m-2024-s7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得澳洲交易報告和分析中心 AUSTRAC 獨立匯款執照"])},
    "m-2024-s8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得印度尼西亞商品期貨交易監管機構 BAPPEBTI 期貨經紀業務許可證及為另類交易系統參與者批準"])},
    "m-2024-s9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得英屬維京群島金融服務委員會 BVI FSC 投資經理批準證書"])},
    "milestone-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不斷創新變革，鑄就榮耀歷程，每一步都有迹可循"])},
    "milestone-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集團里程碑"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使命"])},
    "missionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為客戶提供安全可靠、專業、便捷的一站式優質金融服務與創新解決方案。"])},
    "paymentExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兌換"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品革新"])},
    "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全監管"])},
    "sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "strictSupervision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嚴格監管 多重保障"])},
    "strictSupervisionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 作為國際領先的金融服務集團，目前已持有 20+ 國際監管牌照，不斷完善全球金融監管格局。"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 视服务全球投资者为使命，不断扩大全球业务版图，满足全球客户需求"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球运营中心"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["倫敦"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價值觀"])},
    "values-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶為先"])},
    "values-1-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們致力於為每一位客戶提供專業的服務、滿意的體驗，持續地為客戶創造價值。"])},
    "values-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誠信、可靠及公平公正"])},
    "values-2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作為一家金融公司，確保客戶資產安全及權益受到公平公正的對待，是我們的核心責任。"])},
    "values-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業社會責任"])},
    "values-3-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從創立開始，我們就不認為盈利是企業的唯一目標。 我們將敢於承擔應有的企業社會責任，致力為個人、行業和社會的發展持續帶來貢獻。"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["願景"])},
    "visionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成為一家以金融科技為核心、領先全球的金融服務集團。"])},
    "wealthManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財富管理"])}
  },
  "aufc": {
    "AdelaideUnited-2003-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立於 2003 年 9 月"])},
    "AdelaideUnited-2003-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼西阿德萊德隊和阿德萊德城隊退出後，阿德萊德聯隊加入了 2003/04 賽季的全國足球聯賽（NSL）。"])},
    "AdelaideUnited-2008-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\n阿德萊德聯隊在亞冠決賽巔峰對決中，1-0 力克日本勁旅鹿島鹿角，鑄就歷史輝煌，榮耀加冕，首開澳足問鼎亞洲之巔新紀元。"])},
    "AdelaideUnited-2013-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬科斯·弗洛雷斯（Marcos Flores）首次獲得約翰尼·沃倫獎章（Johnny Warren Medal），並被評為聯盟最佳球員。"])},
    "AdelaideUnited-2013-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前鋒塞爾吉奧·範戴克（Sergio van Dijk）在常規賽中打進 16 球，成為最佳射手，並獲得了澳大利亞足球超級聯賽金靴獎。"])},
    "AdelaideUnited-2016-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 A 聯賽總決賽中戰勝昆士蘭獅吼隊獲得冠軍"])},
    "AdelaideUnited-2022-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成為 Doo Group 官方合作夥伴"])},
    "AdelaideUnited-2023-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿德萊德聯隊慶祝成立 20 周年 "])},
    "AdelaideUnited-2023-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級為 Doo Group 全球金牌合作夥伴"])},
    "DooGroup-2014-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Doo Group  成立，以金融科技集團模式運營"])},
    "DooGroup-2014-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在中國香港設立首個運營中心"])},
    "DooGroup-2022-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Wealth 財富管理品牌"])},
    "DooGroup-2022-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客戶達 60,000+"])},
    "DooGroup-2022-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成為阿德萊德聯足球俱樂部官方合作夥伴"])},
    "DooGroup-2023-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客戶達 200,000+"])},
    "DooGroup-2023-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Payment 支付兌換品牌"])},
    "DooGroup-2023-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Academy 理財教育品牌"])},
    "DooGroup-2023-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈 Doo Health 大健康品牌"])},
    "DooGroup-2023-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成為曼聯足球俱樂部官方全球合作夥伴"])},
    "DooGroup-2023-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成為西澳大利亞州澳中友好協會（ACFSWA）榮譽贊助商"])},
    "DooGroup-2023-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級為 AUFC 全球金牌合作夥伴"])},
    "ambition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雄心壯志"])},
    "ambition-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["憑藉對熱情和卓越的堅定承諾，Doo Group 和 AUFC 不斷突破界限，達到新的高度。"])},
    "aufc-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於"])},
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攜手共創未來"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 和阿德萊德聯隊努力追求更高目標"])},
    "coin-toss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 和 AUFC 在全球金牌合作盛典拋硬幣開球環節"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作夥伴"])},
    "community-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的十大主營業務涵蓋廣泛的金融服務，為客戶提供全面的金融服務和創新解決方案，以探索無限可能。"])},
    "community-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作夥伴"])},
    "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUFC 與當地社區之間緊密聯繫"])},
    "cooper-stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["庫珀斯體育場（Coopers Stadium）\n澳大利亞足球聯合會引以為豪的主場"])},
    "dg-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在澳大利亞足球超級聯賽主客場的球員短褲上印有 Doo Group 的標誌"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發現&#064;Doo Group"])},
    "discover-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保持聯繫！關注我們的社交媒體以獲取最新活動資訊、見解。"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在庫珀體育場的每一場主場盛宴中，均可看到 Doo Group 的 LED 顯示屏"])},
    "doo-group-aus-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite 1302, Level 13, Tower 1, 475 Victoria Avenue, Chatswood NSW 2067."])},
    "doo-group-aus-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯繫方式：   02 8801 3188"])},
    "doo-group-aus-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 悉尼總部"])},
    "doo-group-chatswood-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop 3, Ground Floor, 408-414 Victoria Avenue, Chatswood NSW 2067"])},
    "doo-group-chatswood-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯繫方式：   02 8801 3186"])},
    "doo-group-chatswood-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 查茲伍德辦事處"])},
    "doo-group-chinatown-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop D1, Sussex Centre, 56 Dixon Street, Haymarket NSW 2000"])},
    "doo-group-chinatown-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯繫方式：02 8801 3183"])},
    "doo-group-chinatown-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 唐人街辦事處"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
    "goals-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 與阿德萊德聯隊正攜手共繪璀璨未來，並肩奮鬥，共創輝煌成功的篇章。"])},
    "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光時刻"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光時刻"])},
    "highlights-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索兩家巨頭的悠久歷史和輝煌成就"])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忠誠"])},
    "loyalty-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 通過提供專業的服務和持續的價值增值來體現其忠誠。同時，阿德萊德聯隊 (AUFC) 作為南澳大利亞人的守護者，以足球為媒介，點燃激情的火焰，激發深藏的自豪感，引領著每一位支持者共築遠大目標。"])},
    "partnership-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請繼續關注我們與阿德萊德聯隊合作的最新進展。"])},
    "seo": {
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 與阿德萊德聯隊攜手邁向更高的目標，共創美好未來"])},
      "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿德萊德聯隊, Doo Group"])}
    },
    "transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透明度"])},
    "transparency-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 始終堅持透明原則，以保障客戶權益。正如 AUFC 在所有業務中始終踐行“以誠為基，溝通無界，資訊透明，責任在肩”的承諾。"])}
  },
  "banner": {
    "anniversary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group <br>十周年慶"])},
    "anniversaryDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同行 · 突破 ─ 探索美好未來"])},
    "bottom-text-DSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升龍卡由DCS Card Centre Pte. Ltd.發行"])},
    "description-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 是一家以金融科技為核心的國際金融服務集團，透過深度佈局全球券商業務、財富管理、支付兌換以及不動產四大核心業務線，持續建構全方位的商業生態體系，致力於以全面的產品和一站式服務為您的成功鋪平道路。"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在線金融交易平台合作夥伴"])},
    "header-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索美好未來"])},
    "header-sub-DSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無界支付，悅享專屬禮遇"])},
    "headerDSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出聯名信用卡 <br> ─ 升龍卡"])},
    "headerDSCMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出聯名信用卡 <br> ─ 升龍卡"])},
    "hongkongEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港金融科技週 2023：<br>Doo Group 精彩回顧"])},
    "hongkongEventDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "ssf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡金融科技節<br>Doo Group 精彩回顧"])},
    "ssfDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tokenDubaiDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎蒞臨 <span>M5 展位</span>"])},
    "tokenDubaiHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 即將精彩亮相<br>Token2049 迪拜峰會"])},
    "tokenDubaiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://blog.doo.com/events/enhancing-doo-groups-presence-in-the-new-market-with-token-2049-dubai"])}
  },
  "better-future": {
    "doo-clearing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing 旨在為機構客戶提供證券、期貨、外匯、差價合約等金融產品的流動性接入、訂單執行、結算、托管及清算服務。"])},
    "doo-cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Cloud 整合領先技術打造滿足全球市場需求的全包式雲端服務解決方案，確保客戶獲得頂級的產品和服務體驗。"])},
    "doo-consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting 提供量身訂製的業務支援和多元化諮詢服務，以暢通無阻的服務體驗協助客戶海外發展。"])},
    "doo-digital-marketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Digital Plus 透過創新的數字化營銷方案，協助企業在數位化時代建立品牌影響力。"])},
    "doo-finance-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 旨在為專業投資者提供全球證券、期貨、差價合約等金融產品的交易經紀服務，實現一鍵配置全球金融資產。"])},
    "doo-finpoints-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinPoints 通過將科技和創新深度融合，打造廣泛、全面和先進的解決方案，用科技的力量革新全球金融業。"])},
    "doo-payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過 Doo Payment 開設全球賬戶，以先進的金融科技輕鬆實現全球資金流動，高效把握數字時代的商業機遇。"])},
    "doo-prime-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP Doo Prime 旨在為專業投資者提供全球金融產品的差價合約交易經紀服務，致力於成為您的私人專屬券商。"])},
    "doo-property-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "doo-property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Property 致力打造優質的房地產交易和投資服務精準滿足客戶需求，提升客戶的置業體驗。"])},
    "doo-wealth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth 旨在為投資者提供一站式的財富管理服務，涵蓋保險、證券、全球基金、房地產、身分規劃、信託及家族辦公室，致力於打造更低門檻、更安全、更輕鬆的環球投資體驗。"])},
    "financial-education-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Academy 旨在為廣大投資愛好者提供精確的理財知識，拓展個人投資視野，實現財富目標。"])},
    "health-care-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Health 旨在為客戶提供一站式的數字化大健康服務平台，打造更簡單、更安全、更輕鬆的健康管理體驗。"])},
    "lesspay-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過 LessPay 開設全球賬戶，以先進的金融科技輕鬆實現全球資金流動，高效把握數字時代的商業機遇。"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 旗下十大業務主線涵蓋金融服務的多個領域，為客戶提供全方位的金融服務與創新解決方案，探索無限可能。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十大業務"])},
    "title-brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商業務"])},
    "title-cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲服務"])},
    "title-consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["諮詢業務"])},
    "title-digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數字營銷"])},
    "title-finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "title-financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理財教育"])},
    "title-health-care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "title-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兌換"])},
    "title-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不動產"])},
    "title-wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財富管理"])}
  },
  "brokerage": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一鍵配置全球金融資產"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商業務"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解券商業務詳情，或探索更多合作機遇"])},
    "doo-clearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing"])},
    "doo-clearing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一家流動性提供商，旨在為機構客戶提供證券、期貨、外匯、差價合約等金融產品的流動性接入、訂單執行、結算、託管及清算服務。"])},
    "doo-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial"])},
    "doo-financial-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為准求高監管標準的專業客戶提供全球證券、期貨、外匯、差價合約和基金交易的一站式投資服務。"])},
    "doo-prime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime"])},
    "doo-prime-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一家全球領先的互聯網券商，為專業投資者提供全球證券、期貨、貨幣對、貴金屬、大宗商品和股票指數的差價合約交易產品。"])}
  },
  "business-overview": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 發展多元化業務，引領客戶走向更美好的未來！"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業務概覽"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商業務"])},
    "brokerage-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為機構客戶和專業投資者提供證券、期貨、貨幣對和差價合約等金融產品的交易經紀服務，一鍵配置全球金融資產。 "])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲服務"])},
    "cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合先進技術的領先雲端服務產品和解決方案，為滿足全球市場需求而設計。"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["諮詢業務"])},
    "consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根據客戶需求量身訂做業務支援和多元化諮詢服務，以暢通無阻的服務體驗協助客戶海外發展。"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎聯繫我們"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解多元業務詳情，或探索更多合作機遇 "])},
    "contact-us-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填寫表格"])},
    "digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數字營銷"])},
    "digital-marketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以創新的數字營銷解決方案實現精準營銷，協助企業拓展全球品牌影響力。"])},
    "financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理財教育"])},
    "financial-education-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打造專業的理財教育平台，以豐富的理財知識助力客戶做出明智的投資決策，實現財富目標。 "])},
    "fintech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "fintech-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以領先金融科技全面支持客戶的業務發展，提供清算系統、市場數據等多元化的金融科技解決方案。 "])},
    "health-care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "health-care-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康是最寶貴的財富，我們致力於通過精准醫療服務，助力客戶找到更健康、更美麗的自己。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兌換"])},
    "payment-exchange-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以量身定制的全球支付解決方案打造未來支付場景，為客戶提供高效、便捷的資金管理體驗。  "])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不動產"])},
    "property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結合專業建議、高效管理模式以及成熟房產經紀經驗，以優質的房地產交易和投資服務精準滿足客戶需求。"])},
    "six-major-business-lines-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 以「無界」概念重新構想金融生態的更多可能性，打造無界融合的商業生態圈。 基於領先的技術支持，Doo Group 致力透過不斷的創新和變革，創造出一個潛力無窮的未來。"])},
    "six-major-business-lines-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佈局十大業務領域<br>建構無界融合的商業生態圈"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財富管理"])},
    "wealth-management-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基於客戶的財富目標與風險偏好，提供專業的財富顧問服務及一站式投資理財方案，實現資產的有效管理、增值和傳承。 "])}
  },
  "cloud": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以卓越雲技術協助創新發展"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲服務"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解雲端服務業務詳情，或探索更多合作機遇"])},
    "doo-cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Cloud"])},
    "doo-cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由先進的雲計算、硬件設施和無縫 IT 整合技術支援的頂級產品和服務，助力企業透過前沿的雲端服務解決方案實現高效發展。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "common": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "language-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language:"])},
    "sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简体中文"])},
    "tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繁體中文"])}
  },
  "consulting": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["策略規劃佈局全球業務"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["諮詢業務"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解諮詢業務詳情，或探索更多合作機遇"])},
    "doo-consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting"])},
    "doo-consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全面的全球商務諮詢品牌，透過簡化流程提供高效率的公司秘書、公證認證、移民簽證等專業解決方案。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "contact_us": {
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澳洲"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇希望查詢的品牌（可多選）"])},
    "business_cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商務合作"])},
    "checkbox_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請至少選擇一項。"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機構/公司名稱"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塞浦路斯"])},
    "dubai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迪拜"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵地址"])},
    "email_notValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的電子郵件地址"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留下您的聯繫方式"])},
    "hCaptcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫 hCaptcha"])},
    "hong_kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "london": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["倫敦"])},
    "media_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳媒採訪"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留言"])},
    "message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要留言"])},
    "new_york": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紐約"])},
    "number_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), "必須是數字"])},
    "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢類型"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), "為必填項"])},
    "service_consultation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務諮詢"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "south_africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南非"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎留下您的聯繫方式，Doo Group 專屬顧問會在 1 個工作日內與您聯繫。"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即提交"])},
    "submit_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息发送失败。 "])},
    "submit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢您的留言。 已发送。"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話 (包括國家編號)"])},
    "time_zone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來自全球不同國家和地區的 200+ 客服及 300+ 技術人員 提供的全天候多語言的頂級服務支援"])},
    "time_zone_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一流的國際客戶服務"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])}
  },
  "digitalMarketing": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟品牌數字營銷大時代"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數字營銷"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解數字營銷業務詳情，或探索更多合作機會"])},
    "doo-digitalMarketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Digital Plus"])},
    "doo-digitalMarketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過創新的數字化營銷方案，助力企業在數字時代建立品牌影響力。 我們提供全面的數字營銷方案，涵蓋付費廣告、數據分析、SEO、網站設計及開發、設計/影片製作、內容及社群媒體營銷等。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "esg": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["踐行 ESG 理念，以可持續發展為目標，在集團穩步發展的同時，為促進社會發展、實現美好未來不遺餘力。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 行動"])}
  },
  "finTech": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領先科技開創金融新時代"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解金融科技業務詳情，或探索更多合作機會"])},
    "finpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinPoints"])},
    "finpoints-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球領先的金融科技提供商，將科技和創新深度融合，提供全面的解決方案和產品，包括關鍵級金融雲服務器、市場數據分發系統、訂單管理系統、市場數據和交易用戶端。"])}
  },
  "financial-education": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讓投資理財成就價值人生"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理財教育"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解理財教育業務詳情，或探索更多合作機會"])},
    "doo-academy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Academy"])},
    "doo-academy-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打造全面的理財教育平台，通過線上/線下課程、名師直播、專屬研習小組等一系列服務和產品，為客戶提供專業的理財資訊。"])}
  },
  "footer": {
    "Au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿德雷德聯"])},
    "ESG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "Mu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曼聯"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於我們"])},
    "body": {
      "brand-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing, Doo Financial, Doo Wealth, Doo Payment and Exchange, LessPay, FinPoints, Doo Academy, Doo Health, Peter Elish 和 Doo Consulting是Doo Group 所擁有的商用名稱、注冊商標和知識産權。"])},
      "brand-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們不向某些轄區的個人和公司提供服務，包括但不限於阿富汗，阿塞拜疆，波斯尼亞和黑塞哥維那，緬甸，加拿大，剛果，古巴，埃塞俄比亞，圭亞那，海地，香港，印度尼西亞，伊朗，伊拉克，以色列，日本，黎巴嫩，馬來西亞，馬里，朝鮮，新加坡，斯里蘭卡，索馬里 ，特立尼達和多巴哥，突尼斯，委內瑞拉，阿拉伯聯合酋長國，美國。我們網站上的信息和服務不適用於在此類信息和服務的發布違反各自當地法規的國家或司法管轄區。來自上述地區的訪客應在使用我們的服務之前，確認您對我們的服務進行投資的決定是否符合您居住國家/地區的法規。我們保留隨時更改，修改或終止我們的產品和服務的權利。"])},
      "brand-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們有各種受監管和許可的實體提供相關的金融服務。您可以與我們的任何實體建立業務關係。所選擇的實體意味著您的交易賬戶，交易活動和資金只由這特定的監管機構進行監管。"])},
      "clearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "clearing-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF 的公司， 公司註冊編號為10684079。"])},
      "clearing-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited 是一家期貨、外匯經紀商和流動性供應商，受英國金融行為監管局（UK FCA）授權和監管， 監管編號為833414。"])},
      "client-description-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本網站由 Doo Group 持有和運營。 © 2023 Doo Group。版權所有。"])},
      "client-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此處顯示的任何交易符號僅用於說明目的，不構成我們的任何建議。本網站上提供的任何評論，陳述，數據，信息，材料或第三方材料（“材料”）僅供參考。該材料僅被認為是市場傳播，不包含，也不應被解釋為包含任何交易的投資建議和/或投資建議。儘管我們已盡一切合理的努力確保信息的準確性和完整性，但我們對材料不做任何陳述和保證，如果所提供信息的任何不准確和不完整，我們也不對任何損失負責，包括但不限於利潤損失，直接或間接損失或損害賠償。未經我們的同意，您只能將該材料用於個人用途，不得複制，複製，重新分發和/或許可該材料。"])},
      "client-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們完全遵守司法管轄區中所有適用的法律和法規。請確保您的投資符合要求是您的責任，您將承擔投資和交易的所有後果。"])},
      "client-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們使用我們網站上的cookies來根據您的喜好自定義我們網站上顯示的信息和體驗。通過訪問本網站，您承認您已經閱讀並同意上述詳細信息，並同意我們使用cookies。"])},
      "compliance-description-eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此處顯示的任何交易符號僅用於說明目的，不構成我們的任何建議或推薦。在與我們進行任何交易之前，請確保您完全了解使用相應金融工具進行交易的風險。如果您不了解此處說明的風險，則應尋求獨立的專業建議。"])},
      "compliance-description-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有些當地法律法規禁止或限制您訪問，下載，分發，傳播，共享或以其他方式使用本網站上發布的任何或所有文檔和信息的權利。"])},
      "compliance-description-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必須至少年滿18歲，然後才能訪問我們的網站產品和服務。通過訪問我們的網站，您確認您符合了年齡的要求。"])},
      "compliance-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本網站由 Doo Group 旗下的 Doo Technology Singapore Pte. Ltd.擁有和運營。"])},
      "compliance-description-seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去的投資表現並不代表其未來的表現。"])},
      "compliance-description-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於基礎金融工具的價值和價格會有劇烈變動，股票，證券，期貨，差價合約和其他金融產品交易涉及高風險，可能會在短時間內發生超過您的初始投資的大額虧損。"])},
      "compliance-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該網站可在全球範圍內訪問，並不特定於任何實體。這主要是為了信息集中展示和對比的便利，你的實際權利和義務會基於你所選擇的實體從監管所獲得的授權和監管所決定。"])},
      "compliance-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Technology Singapore Pte. Ltd. 公司註冊地址為 1 Temasek Avenue, #24 – 01A, Millenia Tower, Singapore 039192，公司註冊號為 202104581D。"])},
      "dooConsulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooConsulting-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家根據香港法例第29章《受託人條例》註冊的香港信託公司，註冊地址位於 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司註冊編號為 2601567 。"])},
      "dooConsulting-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited 是一家持牌信託或公司服務提供者，受香港公司註冊處（信託或公司服務提供者牌照）授權和監管， 牌照編號為 TC006709。"])},
      "dooFintechHKLimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Fintech HK Limited"])},
      "dooFintechHKLimited-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊及辦公地址位於 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司註冊編號為 2243363。"])},
      "dooHolding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooHolding-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司註冊編號為2169884。"])},
      "dooHolding-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited 是一家持牌貨幣服務運營商，受香港海關（金錢服務經營者牌照）授權和監管， 監管編號為18-06-02444。"])},
      "dooPrimeMaur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooPrimeMaur-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius 的公司, 公司註冊編號為172054。"])},
      "dooPrimeMaur-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited 是一家持牌投資交易商，受毛裏求斯金融服務委員會（MU FSC）授權和監管， 監管編號為 C119023907。"])},
      "dooPrimeSey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooPrimeSey-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles 的公司， 公司註冊編號為8427202-1。"])},
      "dooPrimeSey-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited 是一家持牌證券交易商，受塞舌爾金融服務管理局（SC FSA）授權和監管， 監管編號為SD090。"])},
      "dooPrimeVanu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooPrimeVanu-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 Govant Building, Po Box 1276, Port Vila, Vanuatu 的公司, 公司註冊編號為700238。"])},
      "dooPrimeVanu-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited 是一家持牌金融交易商，受瓦努阿圖金融服務委員會（ VU FSC）授權和監管， 監管編號為700238。"])},
      "dooProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Properties HK Limited"])},
      "dooProperties-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司註冊編號為3106018。"])},
      "dooProperties-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Properties HK Limited 是一家地產代理公司，受香港地產代理監管局監管， 牌照編號為 C-094694。"])},
      "dooWealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited"])},
      "dooWealth-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司註冊編號為2507030。"])},
      "dooWealth-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited是一家持牌保險經紀公司，受香港保險業監管局（保險經紀牌照）授權和監管， 監管編號為FB1823。"])},
      "ellish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "ellish-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States的公司。"])},
      "ellish-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities 是Elish & Elish Inc 授權的商業名稱。"])},
      "ellish-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc 是一家受美國證券交易委員會（US SEC）和金融業監管局 (US FINRA) 監管的經紀商， 監管編號分別為 SEC: 8-41551和CRD: 24409。"])},
      "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "exchange-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 'Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia 的公司, 澳洲公司編號為 661 717 237。"])},
      "exchange-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd 是貨幣兌換服務和匯款提供商，受澳洲交易報告和分析中心（AUSTRAC）授權和監管， 授權編號分別為 IND100812107-001 和 DCE100812107-001。"])},
      "financialAus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "financialAus-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 'Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia 的公司， 澳洲公司編號為100 139 820。"])},
      "financialAus-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited 是持有澳洲金融服務牌照持的主體，受澳洲證券與投資委員會（ASIC）授權和監管， 監管編號為222650。"])},
      "financialHk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial HK Limited"])},
      "financialHk-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司註冊編號為 2099737。"])},
      "financialHk-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial HK Limited 獲准從事第4類（就證券提供意見）和第9類（提供資產管理）業務，受香港證券及期貨事務監察委員會監管， 中央編號為 BSM562。"])},
      "financialLabuan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "financialLabuan-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家註冊地址位於 Level 2, Lot 19, Lazenda Commercial Centre Phase 3, 87000 FT Labuan, Malaysia 的公司, 公司註冊編號為 LL17644。"])},
      "financialLabuan-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited 是持有證券和金融貨幣經紀牌照的主體，受馬來西亞納閩金融服務管理局（MY Labuan FSA） 授權和監管，監管編號分別為 SL/23/0022 和 MB/23/0108。"])}
    },
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業務和品牌"])},
    "brand-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌和實體公告"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商業務"])},
    "client-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶須知"])},
    "compliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合規與風險披露"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "doogroup.com"])},
    "entities-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo 實體公告"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "follow-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關注我們"])},
    "footerBottom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本網站由 Doo Group 旗下的 Doo Technology Singapore Pte. Ltd.擁有和運營。© ", _interpolate(_named("year")), " 版權所有"])},
    "global-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球辦公室"])},
    "group-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集團介紹"])},
    "hp-hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "hp-hk-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 2632 9557"])},
    "hp-sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "hp-sg-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1736"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入我們"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["監管牌照"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒體中心"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集團里程碑"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "our-partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作夥伴"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業務概覽"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌合作"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兌換"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財富管理"])}
  },
  "future-opportunity": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們誠摯歡迎任何的查詢，不論是服務諮詢還是商務合作，您均可與我們聯繫！"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期待您的聯繫 一同創造無限機遇"])}
  },
  "global-partnership": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們與全球多領域的優質夥伴建立了長期合作關係，共建理想金融生態，實現共同發展和成功。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球合作夥伴"])}
  },
  "global-presence": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 開啟全球化佈局，共設有 20 + 全球運營中心，擁有 1000 + 員工，專注優質本地化服務。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連接世界 佈局全球"])}
  },
  "healthcare": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康科技賦能多彩人生"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解大健康業務詳情，或探索更多合作機會"])},
    "doo-health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Health"])},
    "doo-health-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一個專注於精准醫療與個性化健康的大健康品牌，提供健康檢查 、疫苗接種、抗衰老和幹細胞解決方案，引領健康管理新時代。"])}
  },
  "home-global-presence": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 在全球主要城市設有 20+ 運營中心，業務遍及全球，助您突破投資界限，把握無限機遇。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球佈局"])}
  },
  "latest-updates": {
    "latest-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "license": {
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 旗下實體共持有 20+ 監管牌照，在國際頂尖機構的嚴格監管下為全球客戶提供安全可靠的多元化金融服務。每位客戶的資金安全及交易透明度都得到專業第三方機構的保護，您可以安心選擇我們作為您的金融合作夥伴。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國際牌照"])}
  },
  "mascot": {
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想知道我的故事嗎？"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎來到 Doo Group，<br/>讓我陪你探索美好未來吧！"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你好，<br/>我是嘟嘟！"])}
  },
  "mu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作關係"])},
    "blog_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glory, glory Man United! Sing, cheer and rejoice as renowned football club Manchester United has officially established a multi-year global partnership..."])},
    "blog_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Manchester United formed an alliance at the beginning of 2023 with a multi-year global partnership. The partnership brings football and..."])},
    "blog_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s history in the making as two giants cross paths for a monumental partnership with the event ‘Coming To Light’ this May 2023..."])},
    "blog_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Appointed As An Official Global Partner Of Manchester United"])},
    "blog_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group And Manchester United: United By Shared Values"])},
    "blog_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Man United To Honour Partnership With Grand Launch"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
    "center_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在我們攜手並進的歷程中，我們將共同創造許多令人激動人心的時刻。 加入 Doo Group 和曼聯，讓我們共同努力，創造無限精彩！ "])},
    "center_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攜手並進"])},
    "center_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 共創未來 "])},
    "esg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "first_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與我們一同見證投資交易與足球破門的歷史性時刻"])},
    "first_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從交易場到夢劇場"])},
    "fisrt_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魯本·阿莫林出任曼聯新任主教練，他秉持著一個強有力的信念，可以用一個詞來概括：「團隊」。他所堅信的」團結、協作與集體力量」為俱樂部開啟了一個激動人心的新時代。在 Doo Group，我們同樣致力於團隊合作，並視其為成功的關鍵。這些團結協作與堅韌不拔的價值觀，是我們與曼聯建立堅實合作夥伴關系的核心，也是我們共同追求卓越的動力源泉。"])},
    "fisrt_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簡而言之/關鍵詞：團隊"])},
    "fisrt_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "footerBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本網站由 Doo Holding Group Limited 擁有和運營。"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光時刻"])},
    "hp-hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "hp-hk-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 2632 9557"])},
    "hp-sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "hp-sg-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1736"])},
    "image_with_text_box_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 作為國際領先的金融服務集團，與世界最著名的足球俱樂部——曼徹斯特聯足球俱樂部達成戰略合作，我們將在共同價值觀的驅使下開展一系列合作。 Doo Group 作為曼聯的官方線上金融交易平臺合作夥伴，誠邀您加入我們的精彩之旅。"])},
    "image_with_text_box_strong_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携手同行"])},
    "image_with_text_box_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作使命"])},
    "landing_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以共同價值觀"])},
    "landing_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創造未來"])},
    "landing_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 與曼聯：志同道合 共謀發展"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "latest_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解更多"])},
    "milestone_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歷時幾個月的從點到面的充分溝通，Doo Group 與曼聯於 2023 年 1 月正式拉開合作序幕。Doo Group 突破發展，再創新紀元。"])},
    "milestone_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們與曼聯開展了專項合作研討會，明確了共同的發展目標與願景，鞏固加深了夥伴關係。未來我們將秉承核心的合作使命，開展一系列精彩活動，朝著共同的目標邁進。\n\n我們將滿懷激情，攜手為全球用戶帶來極致體驗。"])},
    "milestone_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 將通過曼聯龐大的球迷群體——攜手數以億計的足球愛好者和投資者開創新征程。2023 年 5 月，一同在曼徹斯特見證我們“光·啟未來”合作盛典的盛大和精彩！"])},
    "milestone_description_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 將為客人提供全年的 VIP 包廂服務，為其在比賽日提供專屬觀賽區。客人可在 VIP 包廂盡享豪華自助餐美食和香檳美酒，並通過合作夥伴展示牆進一步了解  Doo Group 追求卓越的品牌內核。"])},
    "milestone_description_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 9 周年特別版視頻在曼聯主場老特拉福德球場的 LED 展示屏上展出"])},
    "milestone_description_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曼聯官方大使安迪·科爾（Andy Cole）和曼聯合作夥伴關係部門總監利亞姆·麥克馬納斯（Liam MacManus) 參與Doo Group 十週年慶典的晚宴，慶祝Doo Group 輝煌十年，期待透過合作共建更美好的未來"])},
    "milestone_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作共贏"])},
    "milestone_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作研討會"])},
    "milestone_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 年 5 月 “光·啟未來” 合作盛典"])},
    "milestone_title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group VIP 包廂：帶給您奢華觀賽體驗"])},
    "milestone_title_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 9 周年 LED 展示屏"])},
    "milestone_title_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 十週年盛典的晚宴"])},
    "milestones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["里程碑"])},
    "mu_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共同價值觀"])},
    "mufc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曼聯"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "our_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Jobs"])},
    "our_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Life"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌合作"])},
    "second_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索 Doo Group 與曼聯之間的協作價值"])},
    "second_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 與您同在。我們和您一樣重視財富未來和投資安全。正如曼聯始終堅信沒有什麼比團隊更重要一樣，曼聯球隊的發展和進步離不開團隊合作和堅定的信念。我們深知踐行高標準的團隊合作和職業道德所需要的責任和努力。為此我們持續不斷培養遍佈世界的行業精英，致力於為全球投資者提供極致的金融科技服務。"])},
    "second_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["踐行高標準的"])},
    "second_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["團隊協作和職業道德"])},
    "second_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["核心價值觀"])},
    "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])},
    "third_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欲瞭解 Doo Group 與曼聯合作更多內容，請持續關注。"])},
    "third_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有球迷，足球就失去了意義。對 Doo Group 來說同樣如此。曼聯始終銘記其歷史使命，深知俱樂部對球迷的重要意義，並致力於為全世界數百萬球迷提供娛樂。我們始終視服務全球投資者為使命，始終秉承的服務宗旨是：致力於為每一位元客戶提供專業的服務、滿意的體驗，持續地為您創造價值。"])},
    "third_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個體與團體"])},
    "third_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對未來成功的重要性"])},
    "third_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光時刻  "])}
  },
  "navigation": {
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於我們"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商業務"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業務和品牌"])},
    "business-and-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業務和品牌"])},
    "business-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業務概覽"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲服務"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨詢業務"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
    "digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數字營銷"])},
    "esg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "finTech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理財教育"])},
    "global-operational-centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球運營中心"])},
    "group-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集團介紹"])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入我們"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集團里程碑"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作夥伴"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兌換"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不動產"])},
    "regulatory-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["監管牌照"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財富管理"])}
  },
  "payment-exchange": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨心實現全球資金流動"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兌換"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解支付兌換業務詳情，或探索更多合作機會"])},
    "doo-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Payment"])},
    "doo-payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一個支持多種貨幣的支付兌換業務品牌，通過智能化金融科技設施打破地域界限，為客戶提供更便捷、更高效的國際匯款方式。"])}
  },
  "property": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟卓越房地產投資之旅"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不動產"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解不動產業務詳情，或探索更多合作機會"])},
    "doo-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Property"])},
    "doo-property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由資深團隊提供一站式房地產開發服務以及房屋託管和房產經紀人業務，旨在為投資者提供優質的房產交易和投資體驗。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "seo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 是一家以金融科技为核心的金融服务集团，致力于为全球各地的个人及机构客户提供超过 2 万个证券、期货、货币对、差价合约和基金等金融产品的交易经纪和资产管理服务。"])},
  "seo_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group,都会控股集团,全球金融市场,专业交易平台,证券,期货,货币对,差价合约,基金交易"])},
  "seo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group"])},
  "seo_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索更美好的未來"])},
  "stats": {
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客戶"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球運營中心"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機構合作夥伴"])},
    "presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶覆蓋國家/地區"])}
  },
  "token2049": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token2049 迪拜峰會<br/>Doo Group 破浪前行"])},
    "tokenDubaiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://blog.doo.com/sc/events/doo-group-makes-waves-at-token2049-dubai/"])}
  },
  "wealth-management": {
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一站直達理想財富未來"])},
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財富管理"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步了解財富管理業務詳情，或探索更多合作機會"])},
    "doo-wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth"])},
    "doo-wealth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過完善的全球財富管理服務體系，滿足顧客的多元需求。 Doo Wealth 頂級投顧團隊在保險、證券、全球基金、不動產、身分規劃、信託和家族辦公室等領域擁有豐富的經驗，可根據客戶的風險承受能力和財富目標提供量身定制的解決方案。"])}
  }
}