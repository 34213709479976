<template>
  <div :class="['three-blog-row',  overflow ? 'three-blog-row-margin-bottom' : '' ]" :style="{ marginRight: overflow ? 'unset' : 'auto', marginLeft: overflow ? 'unset' : 'auto' }">
    <div ref="scrollRef">
      <a v-for="(item, index) in blogData" :key="item.title" :href="item.href" target="_blank" :class="(index === 0 && overflow) ? 'first-blog' : ''">
        <div class="blog-content">
          <img :src="(item.imgHref || ImageArticle1)" alt="" />
          <div>
            <div class="title">{{ $i18n.locale === 'en' ? item.title.toLowerCase() : item.title }}</div>
            <div class="description line-clamp-3">{{ item.description }}</div>
            <div class="date">{{ item.date }}</div>
          </div>
        </div>
      </a>
    </div>
    <template v-if="overflow">
      <button class="next-button" @click="handleNextOnClick" :disabled="!scrollPosition.right">
        <img :src="scrollPosition.right ? buttonRightOn : buttonRightOff" />
      </button>
      
      <button class="previous-button" @click="handlePreviousOnClick" :disabled="!scrollPosition.left">
        <img :src="scrollPosition.left ? buttonLeftOn : buttonLeftOff" />
      </button>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ImageArticle1 from "@/assets/MuLanding/Image-Article1.png";
import RedArrowRight from "@/assets/MuLanding/icon-arrow-right-red.png";
import buttonLeftOff from "@/assets/MuLanding/Button_arrow_leftOff.png";
import buttonLeftOn from "@/assets/MuLanding/Button_arrow_leftOn.png";
import buttonRightOff from "@/assets/MuLanding/Button_arrow_rightOff.png";
import buttonRightOn from "@/assets/MuLanding/Button_arrow_rihgtOn.png";

const { t, locale } = useI18n();

const blogData = ref([]);
const scrollRef = ref(null);
const blogWidth = 452 + 12;
const scrollPosition = ref({ left: false, right: true });
const overflow = ref(false);


const handleGetBlogData = async () => {

  try {
    const data = await getBlogData(locale.value);
    const imageList = [];
    blogData.value = data.map((item) => {
      imageList.push(getImage(locale.value, item.featured_media));
      return {
        title: removeHtmlTag(item.title.rendered),
        description: removeHtmlTag(item.excerpt.rendered),
        date: item.date.split("T")[0],
        featuredMedia: item.featured_media,
        href: 'https://blog.doo.com' + item.link,
      };
    });
    
    const imageResult = await Promise.all(imageList);
    if (imageResult.length) {
      blogData.value = blogData.value.map((item, index) => ({
        ...item,
        imgHref: imageResult[index].link,
      }));
    }
  } catch (error) {
    console.error("Error fetching blog data:", error);
  } finally {
    handleCheckOverflow();
  }
};

watch(locale, () => {
  handleGetBlogData();
});

onMounted(() => {
  handleGetBlogData();
  if (scrollRef.value) {
    scrollRef.value.addEventListener("scroll", handleScrollEvent);
    window.addEventListener("resize", handleCheckOverflow);
  }
});

onUnmounted(() => {
  if (scrollRef.value) {
    scrollRef.value.removeEventListener("scroll", handleScrollEvent);
    window.removeEventListener("resize", handleCheckOverflow);
  }
});

const handleScrollEvent = () => {
  if (scrollRef.value.scrollLeft === 0) {
    scrollPosition.value.left = false;
    scrollPosition.value.right = true;
  } else if(scrollRef.value?.scrollWidth - (scrollRef.value.scrollLeft + scrollRef.value.offsetWidth) > blogWidth) {
    scrollPosition.value.left = true;
    scrollPosition.value.right = true;
  } else {
    scrollPosition.value.left = true;
    scrollPosition.value.right = false;
  }
};

const handleCheckOverflow = () => {
  overflow.value = scrollRef.value?.scrollWidth > scrollRef.value?.clientWidth;
};

const handleNextOnClick = () => {
  if (scrollRef.value) {
    const scrollDistance= scrollRef.value?.scrollWidth - scrollRef.value?.clientWidth;

    const scrollLeftValue = scrollDistance - (scrollRef.value.scrollLeft + blogWidth) > blogWidth ? scrollRef.value.scrollLeft + blogWidth : scrollRef.value?.scrollWidth;

    scrollRef.value.scroll({
      left: scrollLeftValue,
      behavior: "smooth",
    });
  }
};

const handlePreviousOnClick = () => {
  if (scrollRef.value) {
    scrollRef.value.scroll({
      left: scrollRef.value.scrollLeft - blogWidth,
      behavior: "smooth",
    });
  }
};

const removeHtmlTag = (string) => {
  return string.replace(/(<([^>]+)>)/gi, "");
};

const getBlogData = async () => {

  const currentLocale = locale.value == "en" ? "en" : "sc";

  const link = `https://blog.doo.com/${currentLocale}/wp-json/wp/v2/posts?tags=`;

  const linkList = [`${link}202`, `${link}68`];

  let res = ``;
  let data = [];

  for(let i = 0; i < linkList.length; i++) {
    res = await fetch(linkList[i]);

    if (res && res.status >= 200 && res.status < 300) {

      const newRes = await res.json();

      if(i == 0){
        data = data.concat(newRes);
        continue;
      }

      for(let j = 0; j < data.length; j++){
        for(let k = 0; k < newRes.length; k++){
          if(data[j].id == newRes[k].id) {
            newRes.splice(k, 1);
          }
        }
      }

      data = data.concat(newRes);
      data.sort((a, b) => new Date(b.date) - new Date(a.date));
      
    } else {
      throw new Error();
    }
  }

  return data;
};

const getImage = async (lang, id) => {
  const link = `https://blog.doo.com/wp-json/wp/v2/media/${id}`;
  const res = await fetch(link);
  if (res.status >= 200 && res.status < 300) {
    return res.json();
  } else {
    throw new Error();
  }
};
</script>
